import * as React from "react";
import { Box, Flex } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout-2";
import SEO from "../components/seo";
import { TLink } from "../components/tlink";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Box position="relative" background="black">
      <Box
        position="absolute"
        top="50%"
        left="53%"
        transform="translate(-53%, -50%)"
        height={["40%", "40%", "50%", "50%", "50%"]}
        width={["90%", "80%", "80%", "65%"]}
        zIndex="1"
      >
        <Flex
          direction="column"
          justifyContent="space-between"
          height="100%"
          width={["95%", "95%", "90%", "90%", "80%"]}
        >
          <Box>
            <Box
              as="h1"
              fontFamily="Fjalla One !important"
              fontSize={[
                "60px",
                "80px",
                "70px",
                "95px",
                "90px",
                "120px",
                // "120px",
              ]}
              color="#fff"
              lineHeight={["65px", "80px", "60px", "95px", "95px", "125px"]}
            >
              PAGE NOT FOUND
            </Box>
            <Box
              as="p"
              color="#fff"
              fontSize={["16px", "20px", "20px", "24px", "26px", "27px"]}
              paddingLeft="10px"
              marginTop="15px"
              marginBottom="20px"
              lineHeight={["25px", "33px", "33px", "33px", "33px"]}
            >
              Sorry, we can't find the page you were looking for.
            </Box>
          </Box>
          <TLink to="/">
            <Button
              width={["80%", "65%", "50%", "50%", "30%"]}
              background="#fff"
              color="#282765"
              colorScheme="#fff"
              padding="15px"
              _hover={{ background: "#282765", color: "#fff" }}
              variant="outline"
              borderRadius="25px"
              // onClick={scrollToIndustries}
              // width="150px"
            >
              GO TO HOME
            </Button>
          </TLink>
        </Flex>
      </Box>
      <Box
        display={["block", "none", "none", "none", "none"]}
        position="relative"
        marginTop="60px"
      >
        <StaticImage
          src="../images/404-small.jpg"
          // width={800}
          height={1080}
          placeholder="none"
          // formats={["AUTO", "WEBP", "AVIF"]}
          objectFit="contain"
          alt="Logo"
          style={{ display: "block", opacity: "0.8" }}
          // style={{ width: "100%" }}
        />
      </Box>
      <Box
        display={["none", "block", "none", "none", "none"]}
        position="relative"
        marginTop="60px"
      >
        <StaticImage
          src="../images/404-medium.jpg"
          width={800}
          height={800}
          placeholder="none"
          // formats={["AUTO", "WEBP", "AVIF"]}
          objectFit="contain"
          alt="Logo"
          style={{ display: "block", opacity: "0.8" }}
          // style={{ width: "100%" }}
        />
      </Box>
      <Box
        display={["none", "none", "block", "none", "none"]}
        position="relative"
        marginTop="60px"
      >
        <StaticImage
          src="../images/404.jpg"
          width={1920}
          height={900}
          placeholder="none"
          // formats={["AUTO", "WEBP", "AVIF"]}
          objectFit="contain"
          alt="logo"
          style={{ display: "block", opacity: "0.8" }}
        />
      </Box>
      <Box
        display={["none", "none", "none", "block", "none"]}
        position="relative"
        marginTop="60px"
      >
        <StaticImage
          src="../images/404.jpg"
          width={1920}
          height={800}
          placeholder="none"
          // formats={["AUTO", "WEBP", "AVIF"]}
          objectFit="contain"
          alt="logo"
          style={{ display: "block", opacity: "0.8" }}
        />
      </Box>
      <Box
        display={["none", "none", "none", "none", "block"]}
        position="relative"
        marginTop="60px"
      >
        <StaticImage
          src="../images/404.jpg"
          width={1920}
          height={600}
          placeholder="none"
          // formats={["AUTO", "WEBP", "AVIF"]}
          objectFit="contain"
          alt="logo"
          style={{ display: "block", opacity: "0.8" }}
        />
      </Box>
    </Box>
  </Layout>
);

export default NotFoundPage;
